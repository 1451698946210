let _profile = null;

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $_accountRoles() {
          return _profile && _profile.roles ? _profile.roles : [];
        },
        $_profilePerson() {
          return _profile && _profile.person ? _profile.person : {};
        },
        $_schoolClasses() {
          return _profile && _profile.schoolClasses
            ? _profile.schoolClasses.sort((a, b) =>
                a.code.localeCompare(b.code)
              )
            : [];
        },
        $_classTeacherOf() {
          return _profile && _profile.classTeacherOf
            ? _profile.classTeacherOf
            : [];
        },
        $_currentTerm() {
          return _profile && _profile.currentTerm ? _profile.currentTerm : {};
        },
      },
      methods: {
        // load Settings from Server if not in localstorage
        async $_loadSettings() {
          if (localStorage.getItem("peopleSort") == null) {
            const peopleSort = (
              await this.apiList({
                resource: "app/settings",
                query: "key=peopleSort",
              })
            ).value;
            if (peopleSort) {
              localStorage.setItem("peopleSort", peopleSort);
            }
          }
          if (localStorage.getItem("emailClient") == null) {
            const emailClient = (
              await this.apiList({
                resource: "app/settings",
                query: "key=emailClient",
              })
            ).value;
            if (emailClient) {
              localStorage.setItem("emailClient", emailClient);
            }
          }
          if (localStorage.getItem("hideIcons") == null) {
            const hideIcons = (
              await this.apiList({
                resource: "app/settings",
                query: "key=hideIcons",
              })
            ).value;
            if (hideIcons) {
              localStorage.setItem("hideIcons", hideIcons);
            }
          }
        },
        $_hasRole(roles) {
          if (Array.isArray(roles)) {
            for (let i = 0; i < roles.length; ++i) {
              if (this.$_accountRoles.includes(roles[i])) return true;
            }
            return false;
          }
          return this.$_accountRoles.includes(roles);
        },
        $_isPerson(person) {
          return (
            _profile &&
            _profile.person &&
            person &&
            _profile.person.id === person.id
          );
        },
        $_isClassTeacherOf(student) {
          return this.$_classTeacherOf.some(
            (el) => el.id == student.schoolClass.id
          );
        },
        async $_loadProfile() {
          _profile = await this.apiList({ resource: "app/profile" });
        },
      },
    });
  },
};
