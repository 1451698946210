export function saveChildRoute(to, next) {
  const key = "childRoute" + to.path.split("/")[1];
  const route = {
    name: to.name,
  };
  if (to.params && to.params.id) {
    route.params = {};
    route.params.id = to.params.id;
  }
  localStorage.setItem(key, JSON.stringify(route));
  next();
}

export function loadChildRoute(to, next, defaultRoute) {
  const key = "childRoute" + to.path.split("/")[1];
  if (localStorage.getItem(key)) {
    try {
      const route = JSON.parse(localStorage.getItem(key));
      if (to.params && to.params.id && to.params.id > 0) {
        route.params = {};
        route.params.id = to.params.id;
      }
      next(route);
      return;
    } catch (ex) {
      // ignore JSON parse exception
    }
  }
  next(defaultRoute);
}
